<template>
  <div dark>
    <v-img
      :src="require('@/assets/pug_bg.jpg')"
      class="grey lighten-2"
      height="300"
      width="100%"
    >
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          col="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          offset="1"
        >
          <h4 class="banner">
            Pay for your insurance renewal
            <br>
            by credit or debit card
            <br>
            <v-row>
              <v-col
                class="headerDialogBtn"
              >
                <renew />
              </v-col>
            </v-row>
          </h4>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
  export default {
    name: 'HomeBanner',
    components: {
      Renew: () => import('@/components/home/Renew'),
    },
  }
  
</script>
